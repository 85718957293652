<template>
  <div>
    <b-card class="form-group">
      <div>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Message</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.message"
              size="sm"
              maxLength="150"
              @change="value.content.message = _sanitize($event)"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Title</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.text"
              size="sm"
              maxLength="100"
              @change="value.content.text = _sanitize($event)"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Description</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.description"
              size="sm"
              maxLength="150"
              @change="value.content.description = _sanitize($event)"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Ask for confirmation</label>
          </b-col>
          <b-col>
            <b-form-checkbox
              v-model="value.content.ifNeedConfirm"
              :value="true"
              :unchecked-value="false"
            ></b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Submit Button Text</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.submitButtonText"
              maxLength="20"
              @change="value.content.submitButtonText = _sanitize($event)"
              size="sm"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Cancel Button Text</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.cancelButtonText"
              maxLength="20"
              @change="value.content.cancelButtonText = _sanitize($event)"
              size="sm"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Confirm Button Text</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.confirmButtonText"
              maxLength="20"
              @change="value.content.confirmButtonText = _sanitize($event)"
              size="sm"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Service not available message</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.serviceNotAvailable"
              maxLength="100"
              @change="value.content.serviceNotAvailable = _sanitize($event)"
              size="sm"
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="6" lg="4">
            <label class="control-label">Data not available message</label>
          </b-col>
          <b-col>
            <b-form-input
              v-model="value.content.dataNotAvailable"
              maxLength="100"
              @change="value.content.dataNotAvailable = _sanitize($event)"
              size="sm"
            />
          </b-col>
        </b-row>
        <b-row class="border-bottom mb-2">
          <b-col cols="3">
            <label class="control-label">Field</label>
          </b-col>
          <b-col cols="3">
            <label class="control-label">Title</label>
          </b-col>
          <b-col cols="3">
            <label class="control-label">Description</label>
          </b-col>
          <b-col cols="2">
            <label class="control-label">Required?</label>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <div
          class="border-bottom pb-2 mb-2"
          v-for="(field, index) in value.content.field_list"
          :key="index"
        >
          <b-row>
            <b-col cols="3" class="d-inline-block text-truncate">
              <span>{{ field.name }}</span>
            </b-col>
            <b-col cols="3" class="d-inline-block text-truncate">
              <span>{{ field.title }}</span>
            </b-col>
            <b-col cols="3" class="d-inline-block text-truncate">
              <span>{{ field.description }}</span>
            </b-col>
            <b-col cols="1">
              <b-icon-check-circle
                v-if="field.ifRequired"
                variant="success"
                class="ml-3"
              />
              <b-icon-dash-circle v-else variant="danger" class="ml-3" />
            </b-col>
            <b-col cols="2">
              <b-icon-dash-square
                v-if="value.content.field_list.length > 1"
                class="clickable float-right ml-3"
                variant="danger"
                shift-v="-5"
                @click="RemoveField(field)"
              />
              <b-icon-pencil
                class="clickable float-right"
                variant="primary"
                shift-v="-5"
                @click="EditField(field)"
              />
            </b-col>
          </b-row>
        </div>

        <b-row class="form-group">
          <b-col>
            <span class="text-primary clickable" @click="AddField"
              ><b-icon-plus /> Add field</span
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="text-muted">
              Use the field in the request path, params, headers or data by
              typing [Field Name]. <br />
              e.g. /wallets/[wallet]
            </p>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal v-model="previewDropDownListModal" ok-only hide-header centered>
      <b-row>
        <b-col cols="8">
          <b-select :options="previewDropDownListItems" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-model="fieldModal"
      centered
      size="lg"
      title="Add Field"
      @ok="SubmitField"
    >
      <b-form ref="fieldForm">
        <b-row class="form-group">
          <b-col cols="12" md="3"> Name </b-col>
          <b-col>
            <b-form-input
              v-model="fieldEdit.name"
              size="sm"
              placeholder="e.g. wallet"
              @keydown.32.prevent
              maxLength="50"
              @change="fieldEdit.name = _alphanumeric($event)"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="3"> Title </b-col>
          <b-col>
            <b-form-input
              v-model="fieldEdit.title"
              size="sm"
              placeholder="e.g. Wallet"
              maxLength="150"
              @change="fieldEdit.title = _sanitize($event)"
              required
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="3"> Description </b-col>
          <b-col>
            <b-form-input
              v-model="fieldEdit.description"
              size="sm"
              placeholder="e.g. preferred wallet"
              maxLength="150"
              @change="fieldEdit.description = _sanitize($event)"
            />
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="3"> Required? </b-col>
          <b-col>
            <b-form-checkbox
              class="ml-1"
              v-model="fieldEdit.ifRequired"
              :value="true"
              :unchecked-value="false"
            ></b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="3"> Masked </b-col>
          <b-col>
            <b-form-checkbox
              class="ml-1"
              v-model="fieldEdit.masked"
              :value="true"
              :unchecked-value="false"
            ></b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col cols="12" md="3"> Type </b-col>
          <b-col>
            <b-select
              :options="fieldDataTypes"
              v-model="fieldEdit.type"
              size="sm"
              required
            />
          </b-col>
        </b-row>
        <div v-if="fieldEdit.type === 'dropDownList'" class="form-group">
          <b-row class="form-group">
            <b-col cols="12" md="3"> Options </b-col>
            <b-col>
              <b-form-radio-group
                v-model="fieldEdit.optionsSource"
                :options="['Constants', 'Webhook']"
                name="options-source"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row v-if="fieldEdit.optionsSource === 'Constants'">
            <b-col cols="3"></b-col>
            <b-col>
              <b-form-input
                v-model="fieldEdit.optionsText"
                size="sm"
                placeholder="e.g. Option A; Option B; Option C"
                @change="ProcessOptionsList($event)"
                required
              />
            </b-col>
            <b-col cols="1">
              <b-icon-eye
                title="preview"
                class="clickable"
                @click="PreviewDropDownListItems"
              />
            </b-col>
          </b-row>
          <b-row v-if="fieldEdit.optionsSource === 'Webhook'">
            <b-col cols="3"></b-col>
            <b-col v-if="webhookTags.length">
              <div class="form-group">
                <b-select
                  v-model="fieldEdit.optionsWebhook.tag_name"
                  size="sm"
                  required
                  :options="webhookTags"
                  @change="ProcessInputChanged"
                />
              </div>
            </b-col>
            <b-col v-else>
              <div class="form-group">
                <small class="text-danger"
                  >To use data from webhook result: <br />
                  Add the url into Process above and select result type , "Use
                  in DropDownList" .</small
                >
                <b-input style="visibility: hidden" v-invalid />
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="px-3">
          <b-row class="form-group">
            <b-col>
              <small class="text-muted"
                >Match Expression settings (optional)</small
              >
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="12" md="3"> Data Type </b-col>
            <b-col>
              <b-form-select
                v-model="fieldEdit.dataType"
                size="sm"
                :options="dataTypes"
                :required="Boolean(fieldEdit.varExpressions)"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="12" md="3"> Expression </b-col>
            <b-col>
              <b-form-input
                v-model="fieldEdit.varExpressions"
                size="sm"
                placeholder="e.g. 叫 or 名字"
                :required="Boolean(fieldEdit.dataType)"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="12" md="3"> Length Range </b-col>
            <b-col>
              <b-form-input
                v-model="fieldEdit.lenRange"
                size="sm"
                placeholder="e.g. 2-5"
                maxLength="30"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col cols="12" md="3"> Preferred Direction </b-col>
            <b-col>
              <b-form-select
                v-model="fieldEdit.prefDir"
                size="sm"
                :options="preferredDirections"
              />
            </b-col>
          </b-row>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  /*global _*/
  name: 'formBuilder',
  props: ['value', 'webhookTags'],
  data() {
    return {
      formItem: {
        requireForm: false,
        type: 'form',
        content: {
          text: '',
          message: '',
          ifNeedConfirm: false,
          cancelButtonText: 'Cancel',
          submitButtonText: 'Submit',
          confirmButtonText: 'Confirm',
          serviceNotAvailable: '',
          dataNotAvailable: '',
          field_list: [
            {
              name: 'prompt',
              title: 'Prompt',
              description: '',
              type: 'text',
              value: '',
              ifMasked: true,
              ifRequired: false,
              options: [],
              optionsText: '',
              optionsSource: 'Constants',
              optionsWebhook: {},
              dataType: '',
              varExpressions: '',
              lenRange: '',
              prefDir: '',
              masked: false, //for changing the field to asterisks
            },
          ],
        },
      },
      fieldEdit: {},
      fieldDefault: {
        name: '',
        title: '',
        description: '',
        type: 'text',
        value: '',
        ifMasked: true,
        ifRequired: false,
        options: [],
        optionsText: '',
        optionsSource: 'Constants',
        optionsWebhook: {},
        dataType: '',
        varExpressions: '',
        lenRange: '',
        prefDir: '',
      },
      fieldModal: false,
      fieldDataTypes: [
        { text: 'Text', value: 'text' },
        { text: 'Text Area', value: 'textArea' },
        { text: 'Date Picker', value: 'datePicker' },
        { text: 'Drop Down List', value: 'dropDownList' },
      ],
      previewDropDownListItems: [],
      previewDropDownListModal: false,
      dataTypes: [
        { value: '', text: 'Select' },
        { value: 'int', text: 'Integer' },
        { value: 'float', text: 'Float' },
        { value: 'number', text: 'Number' },
        { value: 'account_number', text: 'Account Number' },
        { value: 'time', text: 'Time' },
        { value: 'datetime', text: 'DateTime' },
        { value: 'username', text: 'Username' },
        {
          value: 'username_nonword',
          text: 'Username (include characters/numbers)',
        },
        { value: 'email', text: 'Email' },
        { value: 'url', text: 'Url' },
        { value: 'str', text: 'Text' },
        { value: 'str-en', text: 'Text(en)' },
        { value: 'str-zh-cn', text: 'Text(zh)' },
        { value: 'str-ko', text: 'Text(ko)' },
        { value: 'str-th', text: 'Text(th)' },
        { value: 'str-vi', text: 'Text(vn)' },
      ],
      preferredDirections: [
        { value: '', text: 'Select' },
        { value: 'left', text: 'Left' },
        { value: 'right', text: 'Right' },
      ],
    };
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
  directives: {
    invalid: {
      // directive definition
      inserted: function (el) {
        el.setCustomValidity('notValid');
      },
    },
  },
  methods: {
    AddField() {
      this.fieldEdit = _.cloneDeep(this.fieldDefault);
      this.fieldModal = true;
      // this.value.content.field_list.push({
      //   name: '',
      //   displayName: '',
      //   type: 'text',
      //   value: '',
      //   ifMasked: true,
      //   ifRequired: false,
      // });
    },
    EditField(field) {
      this.fieldEdit = field;
      this.fieldModal = true;
    },
    RemoveField(field) {
      this.value.content.field_list.splice(
        this.value.content.field_list.indexOf(field),
        1
      );
    },
    ProcessOptionsList(text) {
      this.fieldEdit.options = _.uniq(_.map(text.split(';'), _.trim)).filter(
        function (val) {
          return val.trim().length > 0;
        }
      );
      this.fieldEdit.optionsText = this.fieldEdit.options.join('; ') + ';';
    },
    ProcessInputChanged() {
      if (this.fieldEdit.optionsSource === 'Input') {
        this.fieldEdit.options = this.fieldEdit.optionsWebhook;
      }
    },
    PreviewDropDownListItems() {
      this.previewDropDownListItems = this.fieldEdit.options;
      this.previewDropDownListModal = true;
    },
    SubmitField($event) {
      const vm = this;
      const mainForm = vm.$refs['fieldForm'];
      if (!mainForm.reportValidity()) {
        _.forEach(mainForm, function (input) {
          if (!input.validity.valid) {
            // eslint-disable-next-line no-console
            console.log(input);
          }
        });
        $event.preventDefault();
        return;
      }

      if (!this.value.content.field_list.includes(vm.fieldEdit)) {
        this.value.content.field_list.push(_.cloneDeep(vm.fieldEdit));
      }
    },
  },
};
</script>
