<template>
  <div>
    <!--    <b-card title="Request" title-tag="h6">-->
    <b-row class="form-group">
      <b-col cols="12" md="6" lg="4">
        <b-select
          id="form-method"
          :options="methods"
          v-model="value.method"
          size="sm"
          required
        />
      </b-col>
      <b-col>
        <b-form-input
          v-model="value.url"
          size="sm"
          debounce="500"
          placeholder="e.g. https://domain.co/api/users"
          required
        />
      </b-col>
    </b-row>
    <b-tabs small content-class="mt-2 mx-3">
      <b-tab title="Headers" active>
        <div>
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <span class="mb-1 small-text">Key</span>
            </b-col>
            <b-col>
              <span class="mb-1 small-text">Value</span>
            </b-col>
          </b-row>
          <b-row
            v-for="(header, index) in value.headers"
            :key="index"
            class="mb-1"
          >
            <b-col cols="12" md="4">
              <b-form-input
                v-model="header.key"
                size="sm"
                debounce="500"
                required
              />
            </b-col>
            <b-col>
              <b-form-input
                v-model="header.value"
                size="sm"
                debounce="500"
                required
              />
            </b-col>
            <b-col cols="1">
              <b-icon-dash-square
                class="clickable"
                variant="danger"
                @click="value.headers.splice(value.headers.indexOf(header), 1)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span
                class="clickable text-primary"
                style="font-size: 0.85em"
                @click="value.headers.push({ key: '', value: '' })"
                ><b-icon-plus /> Add header</span
              >
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab title="Body" class="form-group">
        <v-jsoneditor
          v-model="value.json"
          :options="jsonEditorOptions"
          :plus="false"
          :height="'220px'"
          @error="JsonNotValid"
        />
        <small v-if="jsonNotValid" class="text-danger"
          >JSON format is invalid</small
        >
        <b-input style="visibility: hidden" ref="json-error" />
      </b-tab>
      <!--        <b-tab title="Params">-->
      <!--          <div>-->
      <!--            <b-row>-->
      <!--              <b-col cols="12" md="6" lg="4">-->
      <!--                <label class="control-label">Key</label>-->
      <!--              </b-col>-->
      <!--              <b-col>-->
      <!--                <label class="control-label">Value</label>-->
      <!--              </b-col>-->
      <!--            </b-row>-->
      <!--            <b-row v-for="(param, index) in value.params" :key="index" class="form-group">-->
      <!--              <b-col cols="12" md="4">-->
      <!--                <b-form-input v-model="param.key" size="sm" debounce="500" required/>-->
      <!--              </b-col>-->
      <!--              <b-col>-->
      <!--                <b-form-input v-model="param.value" size="sm" debounce="500" required/>-->
      <!--              </b-col>-->
      <!--              <b-col cols="1">-->
      <!--                <b-icon-dash-square class="clickable" variant="danger"-->
      <!--                                    @click="value.params.splice(value.params.indexOf(param),1)"/>-->
      <!--              </b-col>-->
      <!--            </b-row>-->
      <!--            <b-row>-->
      <!--              <b-col>-->
      <!--              <span class="clickable text-primary"-->
      <!--                    @click="value.params.push({key:'',value:''})"><b-icon-plus/> Add param</span>-->
      <!--              </b-col>-->
      <!--            </b-row>-->
      <!--          </div>-->
      <!--        </b-tab>-->
      <!--        <b-tab title="Body" class="form-group">-->
      <!--          <div class="form-group pl-3">-->
      <!--            <small class="text-muted"></small>-->
      <!--            <b-form-radio-group-->
      <!--                v-model="value.body.dataType"-->
      <!--                :options="dataTypes"-->
      <!--                name="datatype"-->
      <!--            ></b-form-radio-group>-->
      <!--          </div>-->
      <!--          <div-->
      <!--              v-if="value.body.dataType === 'application/x-www-form-urlencoded' || value.body.dataType === 'application/json'">-->
      <!--            <v-jsoneditor v-model="value.body.data"-->
      <!--                          :options="jsonEditorOptions" :plus="false" :height="'220px'"-->
      <!--                          @error="JsonNotValid"/>-->
      <!--            <small v-if="jsonNotValid" class="text-danger">JSON format is invalid</small>-->
      <!--            <b-input style="visibility: hidden" ref="json-error"/>-->
      <!--          </div>-->
      <!--          <div v-else-if="value.body.dataType === 'text/plain'">-->
      <!--            <b-form-input v-model="value.body.data" debounce="500" size="sm"/>-->
      <!--          </div>-->
      <!--        </b-tab>-->
    </b-tabs>
    <!--    </b-card>-->
    <hr />
    <b-row class="mt-2 ml-1">
      <b-col cols="12" md="2">
        <span class="small-text">Result Type</span>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-select
          v-model="value.result_handler.type"
          :options="resultTypes"
          size="sm"
          c
          required
        />
      </b-col>
      <b-col cols="12" md="6">
        <b-row>
          <b-col cols="12" md="4">
            <span class="small-text">Tag Name</span>
          </b-col>
          <b-col cols="12" md="8">
            <b-form-input
              v-model="value.result_handler.tag_name"
              size="sm"
              debounce="500"
              required
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      v-if="value.result_handler.type === 'dropDownList'"
      class="mt-2 ml-1"
    >
      <b-col cols="12" md="2">
        <span class="small-text">Key Field Name</span>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-input
          v-model="value.result_handler.actual_value"
          size="sm"
          debounce="500"
          required
        />
      </b-col>
      <b-col cols="12" md="2">
        <span class="small-text">Display Field Name</span>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-input
          v-model="value.result_handler.display_value"
          size="sm"
          debounce="500"
          required
        />
      </b-col>
    </b-row>
    <b-row
      v-else-if="value.result_handler.type === 'message'"
      class="mt-2 ml-1"
    >
      <b-col cols="12" md="2">
        <span class="small-text">Field Name List</span>
      </b-col>
      <b-col cols="12" md="10">
        <b-form-input
          v-model="value.result_handler.field_list"
          size="sm"
          debounce="500"
          required
        />
      </b-col>
      <b-col cols="12" md="6"></b-col>
    </b-row>
    <b-row v-if="value.result_handler.type === 'message'" class="mt-2 ml-1">
      <b-col cols="12" md="2">
        <span class="small-text">Message</span>
      </b-col>
      <b-col cols="12" md="10">
        <b-form-input
          v-model="value.result_handler.message"
          size="sm"
          debounce="500"
          required
        />
      </b-col>
    </b-row>
    <b-row v-else-if="value.result_handler.type === 'system'" class="mt-2 ml-1">
      <b-col cols="12" md="2">
        <span class="small-text">Field Name</span>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-input
          v-model="value.result_handler.display_value"
          size="sm"
          debounce="500"
          required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VJsoneditor from 'v-jsoneditor/src/index';

export default {
  name: 'httpRequestBuilder',
  components: {
    VJsoneditor,
  },
  props: {
    value: {
      type: Object,
    },
    type: {
      type: String,
      default: 'message',
    },
  },
  data() {
    return {
      formItem: {
        method: 'GET',
        url: 'https://',
        headers: [{ key: 'Accept', value: '*/*' }],
        json: {},
        result_handler: {},
        success_message: '',
        fail_message: '',
        //params: [],
        // body: {
        //   dataType: 'text/plain',
        //   data: ''
        // },
      },
      resultHandlerForDropDownList: {
        type: 'dropDownList',
        tag_name: '',
        display_value: '',
        actual_value: '',
      },
      resultHandlerForMessage: {
        type: 'message',
        tag_name: '',
        field_list: '',
      },
      methods: ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'],
      resultTypes: [
        { value: 'system', text: 'Use by System' },
        { value: 'message', text: 'Use in Message' },
        { value: 'dropDownList', text: 'Use in DropDownList' },
      ],
      dataTypes: [
        {
          text: 'x-www-form-urlencoded',
          value: 'application/x-www-form-urlencoded',
        },
        { text: 'JSON', value: 'application/json' },
        { text: 'Plain Text', value: 'text/plain' },
      ],
      jsonEditorOptions: {
        mode: 'code',
        enableTransform: false,
        enableSort: false,
        history: false,
      },
      jsonNotValid: false,
    };
  },
  watch: {
    'value.json': function () {
      let input = this.$refs['json-error'];
      input.setCustomValidity('');
      this.jsonNotValid = false;
    },
    value: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
  methods: {
    JsonNotValid() {
      let input = this.$refs['json-error'];
      input.setCustomValidity('JSON format is invalid');
      this.jsonNotValid = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  font-size: 0.85em;
}
</style>
