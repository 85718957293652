import { render, staticRenderFns } from "./WebhookApiActivityLog.vue?vue&type=template&id=2d87bad4&scoped=true&"
import script from "./WebhookApiActivityLog.vue?vue&type=script&lang=js&"
export * from "./WebhookApiActivityLog.vue?vue&type=script&lang=js&"
import style0 from "./WebhookApiActivityLog.vue?vue&type=style&index=0&id=2d87bad4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d87bad4",
  null
  
)

export default component.exports