<template>
  <div>
    <b-row class="form-group">
      <b-col>
        <h6 class="d-inline-block">Output</h6>
        <b-icon-plus-square
          class="clickable d-inline-block ml-3"
          variant="primary"
          @click="addOutput"
          centered
        />
      </b-col>
    </b-row>
    <b-row
      v-for="output in outputs"
      :key="output.id"
      class="card p-1 pb-2 mb-2 mx-1"
    >
      <b-col>
        <b-row>
          <b-col class="clickable" @click="editOutput(output)">
            <b-badge variant="info"
              >Type: {{ _capitalizeFirstLetter(output.type) }}
            </b-badge>
          </b-col>
          <b-col cols="2" class="pr-1">
            <b-icon-x
              class="clickable float-right"
              variant="secondary"
              @click="removeOutput(output)"
              title="remove"
              shift-v="-2"
              scale="1.2"
            ></b-icon-x>
          </b-col>
        </b-row>
        <b-row class="clickable" @click="editOutput(output)">
          <b-col cols="10" class="text-truncate">
            <div v-if="output.type === 'message'">
              <small class="text-muted">{{ output.message }}</small>
            </div>
            <div v-else-if="output.type === 'form'">
              <b-row>
                <b-col>
                  <small class="text-muted">{{
                    output.form.content.message
                  }}</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <small class="text-muted">Fields: </small>
                  <b-badge
                    variant="secondary"
                    v-for="field in output.form.content.field_list"
                    :key="field.name"
                    class="ml-2"
                    >{{ field.name }}
                  </b-badge>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal v-model="formModal" hide-footer scrollable size="lg">
      <b-row>
        <b-col>
          <output-form
            :output="output"
            :webhookTags="webhookTags"
            @submitForm="submitted"
          ></output-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/*global _*/
import OutputForm from '@/components/Webhooks/OutputForm';

export default {
  name: 'OutputBuilder',
  props: { webhookTags: Array, existingOutputs: [Array, Object] },
  components: { OutputForm },
  data() {
    return {
      formModal: false,
      output: {},
      outputs: [],
    };
  },
  mounted() {
    this.processExistingOutputs();
  },
  watch: {
    existingOutputs() {
      this.processExistingOutputs();
    },
  },
  methods: {
    processExistingOutputs() {
      const outputs = _.cloneDeep(this.existingOutputs) || [];
      _.forEach(outputs, (output) => {
        output.id = this.getId();
      });
      this.outputs = outputs;
    },
    getId() {
      const currentId = _id;
      _id++;
      return currentId;
    },
    addOutput() {
      this.output = _.cloneDeep({ ...{ id: null }, ...defaultOutput });
      this.formModal = true;
    },
    editOutput(output) {
      this.output = _.cloneDeep(output);
      this.formModal = true;
    },
    removeOutput(output) {
      this.outputs = _.filter(this.outputs, (o) => {
        return o.id !== output.id;
      });
      this.$emit('outputsChanged', this.outputs);
    },
    submitted(output) {
      if (output.id) {
        this.outputs = _.unionBy([output], this.outputs, 'id');
      } else {
        output.id = this.getId();
        this.outputs.push(output);
      }
      this.formModal = false;
      this.$emit('outputsChanged', this.outputs);
    },
  },
};
let _id = 1;
const defaultOutput = {
  type: 'message', //message or form
  form: {
    type: 'form',
    content: {
      text: '',
      message: '',
      ifNeedConfirm: false,
      cancelButtonText: 'Cancel',
      submitButtonText: 'Submit',
      confirmButtonText: 'Confirm',
      field_list: [
        {
          name: 'prompt',
          title: 'Prompt',
          description: '',
          type: 'text',
          value: '',
          ifMasked: true,
          ifRequired: false,
          options: [],
          optionsText: '',
          optionsSource: 'Constants',
          optionsWebhook: {},
          dataType: '',
          varExpressions: '',
          lenRange: '',
          prefDir: '',
          masked: false, //for changing the field to asterisks
        },
      ],
    },
  },
  message: '',
  metadata: '',
};
</script>

<style scoped></style>
