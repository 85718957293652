<template>
  <div class="webhook-api-container">
    <div class="webhook-api-header">
      <span class="align-middle font-weight-bold">API</span>
      <webhook-api-recent-activity-log
        class="align-top ml-2"
        :bot-id="botId"
        :webhook-apis="webhookApis"
        @selected="selectedWebhookApiFromActivityLog"
      />
      <b-button
        variant="outline-primary"
        size="sm"
        @click="add"
        class="position-absolute"
        style="right: 16px"
      >
        Create
        <b-icon-plus />
      </b-button>
    </div>
    <div v-if="!webhookApis.length">
      <b-alert show variant="warning"
        >No API for this Bot. Please create one.
      </b-alert>
    </div>
    <div
      v-else
      v-for="api in webhookApis"
      :key="api.webhookApiId"
      @click="selectWebApi(api)"
      class="list-item px-2"
    >
      <span
        class="list-body"
        :class="{ 'list-body-active': webhookApiId === api.webhookApiId }"
        :title="'select [' + api.apiKey + ']'"
      >
        <b-icon
          class="list-head"
          icon="circle-fill"
          variant="secondary"
        ></b-icon>
        {{ api.apiKey }}
        <b-icon-trash
          class="float-right list-item-action-button delete-button"
          @click="deleteItem(api)"
        ></b-icon-trash>
        <b-icon-pencil-square
          class="float-right list-item-action-button edit-button"
          @click="loadItem(api)"
        ></b-icon-pencil-square>
        <webhook-api-activity-log
          class="float-right list-item-action-button history-button"
          :bot-id="botId"
          :webhook-api-id="api.webhookApiId"
        />
      </span>
    </div>

    <b-modal
      v-model="formModal"
      :title="(formItem.webhookApiId ? 'Edit' : 'Add') + ' API'"
      centered
      ok-title="Submit"
      button-size="sm"
      @ok.prevent="addOrEdit"
    >
      <b-form ref="mainForm">
        <b-row>
          <b-col cols="12" lg="3"
            >Name
            <b-icon-info-circle id="form-api-key-info" />
            <b-tooltip target="form-api-key-info" triggers="hover">
              Alphanumeric or underscore
            </b-tooltip>
          </b-col>
          <b-col>
            <b-form-input
              v-model="formItem.apiKey"
              pattern="[a-zA-Z0-9_]+"
              title="Alphanumeric or underscore"
              maxlength="20"
              minlength="3"
              required
            />
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import httpHandler from '@/mixins/HttpHandler';
import _ from 'lodash';
import WebhookApiRecentActivityLog from '@/components/Bots/WebhookApiRecentActivityLog.vue';
import WebhookApiActivityLog from '@/components/Bots/WebhookApiActivityLog.vue';

export default {
  name: 'WebhookApis',
  components: { WebhookApiActivityLog, WebhookApiRecentActivityLog },
  mixins: [httpHandler],
  props: {
    value: Number,
    botId: Number,
  },
  data() {
    return {
      webhookApis: [],
      webhookApiId: null,

      formModal: false,
      formItem: {
        webhookApiId: null,
        apiKey: '',
      },
    };
  },
  mounted() {
    this.webhookApiId = this.value;
    this.getApis();
  },
  watch: {
    botId() {
      this.getApis();
    },
    value: {
      deep: true,
      handler: function () {
        this.webhookApiId = this.value;
      },
    },
  },
  methods: {
    getApis() {
      const botId = this.botId;
      this.httpclient
        .get('/api/webhookApis', { params: { botId: botId } })
        .then((resp) => {
          if (this.botId === botId) {
            if (_.isArray(resp.data) && resp.data.length) {
              this.webhookApis = _.orderBy(resp.data, ['apiKey'], ['desc']);
              this.webhookApiId = this.webhookApis[0].webhookApiId;
              this.$emit('input', this.webhookApiId);
            } else {
              this.webhookApis = [];
              this.webhookApiId = 0;
              this.$emit('input', 0);
            }
          }
        });
    },
    selectWebApi(api) {
      this.webhookApiId = api.webhookApiId;
      this.$emit('input', api.webhookApiId);
    },
    add() {
      this.formItem = _defaultItem();
      this.formModal = true;
    },
    loadItem(api) {
      this.httpclient
        .get('/api/webhookApis/' + api.webhookApiId)
        .then((resp) => {
          this.formItem = resp.data;
          this.formModal = true;
        });
    },
    deleteItem(api) {
      this.$bvModal
        .msgBoxConfirm('Proceed to delete the API [' + api.apiKey + ']?', {
          centered: true,
        })
        .then((confirm) => {
          if (confirm) {
            this.httpclient
              .delete('api/webhookApis/' + api.webhookApiId)
              .then(() => {
                this.getApis();
                this.$bvModal.msgBoxOk(
                  'The API, [' +
                    api.apiKey +
                    '] had been deleted successfully.',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              });
          }
        });
    },
    addOrEdit() {
      const mainForm = this.$refs['mainForm'];
      if (!mainForm.reportValidity()) {
        // _.forEach(mainForm, function (input) {
        //   if (!input.validity.valid) {
        //     // eslint-disable-next-line no-console
        //     console.log(input);
        //   }
        // });
        return;
      }

      let formItem = _.cloneDeep(this.formItem);

      if (formItem.webhookApiId) {
        this.$bvModal
          .msgBoxConfirm('Proceed to edit the API?', { centered: true })
          .then((confirm) => {
            if (confirm) {
              this.httpclient
                .put('api/webhookApis/' + formItem.webhookApiId, formItem)
                .then(() => {
                  this.getApis();
                  this.$bvModal.msgBoxOk(
                    'The API, [' +
                      formItem.apiKey +
                      '] had been edited successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  );
                });
            }
          });
      } else {
        formItem = { ...formItem, ...{ botId: this.botId } };
        this.$bvModal
          .msgBoxConfirm('Proceed to add the API?', { centered: true })
          .then((confirm) => {
            if (confirm) {
              this.httpclient.post('api/webhookApis', formItem).then(() => {
                this.getApis();
                this.$bvModal.msgBoxOk(
                  'The API, [' +
                    formItem.apiKey +
                    '] had been added successfully.',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              });
            }
          });
      }

      this.formModal = false;
    },
    selectedWebhookApiFromActivityLog(webhookApiId) {
      const api = _.find(this.webhookApis, { webhookApiId: webhookApiId });
      this.loadItem(api);
    },
  },
};

const _defaultItem = () => {
  return {
    apiKey: '',
  };
};
</script>

<style scoped>
.webhook-api-container {
  height: calc(100vh - 230px);
}

.webhook-api-header {
  height: 48px;
  margin-bottom: 0.5em;
  padding: 0 0 0.5em 1em;
  border-bottom: 1px #bebebe solid;

  display: inline-block;
  width: calc(100% + 12px);
}

.list-body-active {
  color: #007bff;
}

.list-body {
  display: inline-block;
  font-size: 1.1em;
  margin: 0;
  z-index: 10;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item {
  cursor: pointer;
  display: inline-block;
  width: 100%;
}

.list-item:hover {
  color: #17a2b8;
}

.list-item-action-button {
  z-index: 100;
  position: absolute;
  margin-top: 7px;
  visibility: hidden;
}

.delete-button {
  right: 8px;
}

.edit-button {
  right: 28px;
}

.history-button {
  margin-top: 2px;
  right: 50px;
}

.list-body-active .list-item-action-button {
  visibility: visible;
}

.list-head {
  font-size: 0.3em !important;
}
</style>
