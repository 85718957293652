<template>
  <b-form ref="main-form">
    <b-row class="mb-3">
      <b-col cols="12" md="6" lg="4">
        <label class="control-label" for="output-form-type"
          >Need information from user?</label
        >
      </b-col>
      <b-col>
        <b-form-checkbox
          id="output-form-type"
          v-model="formItem.type"
          value="form"
          unchecked-value="message"
        />
      </b-col>
      <b-col cols="12">
        <small class="text-muted"
          >This will send out a form to prompt user for information. Else, it
          will send the message below.</small
        >
      </b-col>
    </b-row>
    <div v-if="formItem.type === 'form'" class="mb-3">
      <form-builder v-model="formItem.form" :webhook-tags="webhookTags" />
    </div>
    <b-row v-else-if="formItem.type === 'message'" class="mb-3">
      <b-col cols="12" md="3"> Message</b-col>
      <b-col>
        <b-form-input
          v-model="formItem.message"
          size="sm"
          debounce="500"
          required
        />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="3">
        <h6>Metadata</h6>
      </b-col>
      <b-col>
        <b-form-textarea
          style="height: 300px"
          v-model="formItem.metadata"
          size="sm"
          required
        />
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="submitForm"
          >Submit
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
/*global _*/
import FormBuilder from '@/components/FormBuilder';

export default {
  name: 'OutputForm',
  props: { output: Object, webhookTags: Array },
  components: {
    FormBuilder,
  },
  data() {
    const form = _.cloneDeep(_defaultForm);
    return {
      formItem: {
        type: 'message', //message or form
        form: form,
        message: '',
        metadata: '',
      },
    };
  },
  mounted() {
    this.processOutput();
  },
  watch: {
    output() {
      this.processOutput();
    },
  },
  methods: {
    processOutput() {
      if (this.output) {
        this.formItem = _.cloneDeep(this.output);
      } else {
        const defaultForm = _.cloneDeep(_defaultForm);
        this.formItem = {
          type: 'message', //message or form
          form: defaultForm,
          message: '',
          metadata: '',
        };
      }
    },
    submitForm() {
      const mainForm = this.$refs['main-form'];
      if (!mainForm.reportValidity()) {
        _.forEach(mainForm, function (input) {
          if (!input.validity.valid) {
            // eslint-disable-next-line no-console
            console.log(input);
          }
        });
        return;
      }
      this.$emit('submitForm', this.formItem);
    },
  },
};

const _defaultForm = {
  type: 'form',
  content: {
    text: '',
    message: '',
    ifNeedConfirm: false,
    cancelButtonText: 'Cancel',
    submitButtonText: 'Submit',
    confirmButtonText: 'Confirm',
    field_list: [
      {
        name: 'prompt',
        title: 'Prompt',
        description: '',
        type: 'text',
        value: '',
        ifMasked: true,
        ifRequired: false,
        options: [],
        optionsText: '',
        optionsSource: 'Constants',
        optionsWebhook: {},
        dataType: '',
        varExpressions: '',
        lenRange: '',
        prefDir: '',
        masked: false, //for changing the field to asterisks
      },
    ],
  },
};
</script>

<style scoped></style>
